import React from 'react';
import PropTypes from 'prop-types';
import './errorStyle.scss';

// ValidationMsg is to be used inside a Toast upon validation
// errorsArray is an array of strings provided by the Yup validate catch
export default function ValidationMsg({ errorsArray = '' }) {
  return (
    <div>
      <p className="toastifyHeader">Please address these issues:</p>
      <ul style={{ listStyleType: 'circle' }}>
        {errorsArray
          ?.filter((e) => e != '')
          .map((errorString, index) => {
            return <li key={index}>{errorString}</li>;
          })}
      </ul>
    </div>
  );
}

ValidationMsg.propTypes = {
  errorsArray: PropTypes.array,
  message: PropTypes.string
};
