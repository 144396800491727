/**
 * @summary NodeCollectionGrid.js
 * @file Grid component for the node grid on tableview page
 * @returns {JSX}
 * @usedBy NodeCollectionGrid.js
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import KendoGridBase from '../../../shared/ui/kendoGridBase/KendoGridBase';
import {
  gridSelectedDataActions,
  getNodes,
  deleteNode,
  deleteMultipleNodes,
  replaceTableviewNodes,
  clearSelectedNodes
} from '../../../store/nodes/NodeActions';
import store from '../../../store/store';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import DeleteConfirmation from '../modal/DeleteConfirmation';

const NodeCollectionGrid = ({
  setRefresh,
  refresh,
  headers,
  selectedElements,
  setSelectedElements
}) => {
  const defaultHeaders = [
    {
      field: 'selected',
      show: true,
      filterable: false
    },
    {
      field: 'nodeKey',
      title: 'Key',
      show: true,
      filterable: false,
      filter: 'text',
      width: '100px'
    },
    {
      field: 'name',
      title: 'Name',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'description',
      title: 'Description',
      show: true,
      filterable: false,
      filter: 'text',
      cellType: 'ckeditor_content',
      width: '300px'
    },
    {
      field: 'status',
      title: 'Status',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'source',
      title: 'Source',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    },
    {
      field: 'checkedOutBy',
      title: 'Checked Out',
      show: true,
      filterable: false,
      filter: 'text',
      width: '200px'
    }
  ];

  const location = useLocation();

  const [gridState, setGridState] = useState({
    nodecollections: { data: [], total: 0 },
    dataState: { take: 20, skip: 1 },
    gridDynamicColumns: defaultHeaders
  });

  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState([]);
  const [page, setPage] = useState({ skip: 0, take: 25 });
  const [, setLoading] = useState(false);
  const [, setSkipProcessing] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowsStateArray, setSelectedStateArray] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const nodes = useSelector((state) => state.nodeReducer);
  const userObj = useSelector((state) => state.authReducer.userObj);
  const selectedBranch = useSelector((state) => state.authReducer.userObj.selectedBranch[0]);
  const selectedProject = useSelector((state) => state.authReducer.userObj.selectedProject[0]);
  const canvasSelections = useSelector((state) => state.canvasReducer);
  const selectedData = useSelector((state) => state.nodeReducer.selectedData);

  const canvasRedirectSelectionsUpdate = () => {
    const newSelection = canvasSelections.tableFormatted.nodes.map((node) => {
      node.selected = true;
      return node;
    });
    store.dispatch(replaceTableviewNodes(newSelection));
  };

  const setCanvasSelectionInSelectedElements = () => {
    setSelectedElements({
      ...selectedElements,
      nodes: [
        ...selectedElements.nodes,
        ...canvasSelections.tableFormatted.nodes
      ]
    });
  };

  useEffect(() => {
    if (
      Object.values(nodes).length > 1 &&
      location.state?.from === 'canvasPage'
    ) {
      canvasRedirectSelectionsUpdate();
      setCanvasSelectionInSelectedElements();
    }
  }, []);

  const handlePageChange = (e) => {
    e.page.skip = isNaN(e.page.skip) ? 1 : e.page.skip;
    setPage(e.page);
  };

  const handleGridFilterChange = (colFilter) => {
    setRefresh(true);
    setFilter(colFilter || {});
  };

  const handleGridSortChange = (obj) => {
    setRefresh(!refresh);
    // set initial sort order
    if (sort.length === 0 || sort[0].field !== obj[0].field) {
      setSort(obj);
      return;
    }

    // check if we should apply a desc, or reset sort completely
    if (sort[0].field === obj[0].field && sort[0].dir === 'asc') {
      sort[0].dir = 'desc';
      setSort(sort);
    } else if (sort[0].field === obj[0].field && sort[0].dir === 'desc') {
      setSort([]);
    }
  };

  // when selections are made from neighborhood grid, select all nodes found in that neighborhood
  const populateFromNeighborhoodSelection = () => {
    setSelectedStateArray([...selectedData]);
    const newGridState = gridState.nodecollections.data.map((node) => {
      const foundIdx = selectedData.findIndex(
        (selectedNode) => selectedNode.id === node.id
      );
      if (foundIdx > -1) {
        node.selected = true;
      } else {
        node.selected = false;
      }

      return node;
    });
    setSelectedElements({ ...selectedElements, nodes: selectedData });
    setGridState({
      ...gridState,
      nodecollections: { data: newGridState, total: newGridState.length }
    });
  };

  useEffect(() => {
    populateFromNeighborhoodSelection();
  }, [selectedData, canvasSelections]);

  const formatGridData = () => {
    const nodes = { ...store.getState().nodeReducer };
    delete nodes?.selectedData;
    const data = Object.values(nodes)
      .map((node) => {
        if (node && node?.id) {
          node.selected = false;
          return node;
        }
        return null;
      })
      .filter(Boolean);
    let nodecollections = { data: data, total: data.length };

    if (!nodecollections.data.length) {
      nodecollections = { data: [], total: 0 };
    }

    setGridState({
      ...gridState,
      nodecollections,
      dataState: { take: 100, skip: 0 }
    });
  };

  const formatCanvasGridData = () => {
    let nodecollections = {
      data: canvasSelections.tableFormatted.nodes,
      total: canvasSelections.tableFormatted.nodes.length
    };
    setGridState({
      ...gridState,
      nodecollections,
      dataState: { take: 100, skip: 0 }
    });
  };

  const updateGridState = () => {
    // wrapped in promise purpose: allow async actions to finish prior to populating state
    if (
      selectedProject &&
      userObj.selectedBranch[0].id &&
      location.state?.from !== 'canvasPage'
    ) {
      store
        .dispatch(getNodes(selectedProject.id, userObj.selectedBranch[0].id))
        .then(() => {
          formatGridData();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      formatCanvasGridData();
    }
  };

  // When a Row is Selected...
  const selectionChange = (e) => {
    const data = gridState.nodecollections.data.map((collection) => {
      // Adds Selected Data to an Array to Control Button Visibility
      if (collection === e.dataItem) {
        collection.selected = !e.dataItem.selected;
        if (collection.selected === true) {
          // Sets nodes into selectedElements for canvas redirect //
          setSelectedElements({
            ...selectedElements,
            nodes: [...selectedElements.nodes, collection]
          });
          setSelectedStateArray((selectedRowsStateArray) => [
            ...selectedRowsStateArray,
            collection
          ]);
        } else {
          if (selectAll) {
            setSelectAll(false);
          }
          const filteredSelectedState = selectedRowsStateArray.filter(
            (selecteCheck) => selecteCheck.selected === true
          );
          setSelectedStateArray(filteredSelectedState);

          // Removes unselected elements from selectedElements //
          const filteredSelectedElements = [];
          selectedElements?.nodes?.filter((element) => {
            if (collection.id !== element.id) {
              filteredSelectedElements.push(element);
            }
          });
          setSelectedElements({
            ...selectedElements,
            nodes: filteredSelectedElements
          });
        }
      }
      return collection;
    });

    const gridObjs = { ...gridState.nodecollections };
    gridObjs.data = data;
    store.dispatch(gridSelectedDataActions(e.dataItem));
    setGridState({ ...gridState, nodecollections: gridObjs });
  };
  // Handles All Button and Row Click Actions
  const actionHandler = async (action, selectedCollections) => {
    if (action === 'edit' || action === 'doubleclick') {
      navigate(`/nodes/edit`);
    }
    if (action === 'addNode') {
      navigate('/nodes/new');
    }
    if (action === 'delete') {
      setShowModal(true);
    }
  };

  const deleteNodeHandler = async () => {
    if (nodes.selectedData.length > 1) {
      const nodeIds = nodes.selectedData.map((node) => node.id);
      await store.dispatch(
        deleteMultipleNodes(selectedProject, selectedBranch, nodeIds)
      );
      const newGridState = [...gridState.nodecollections.data];
      nodes.selectedData.forEach((deletedNode) => {
        const nodeIdx = newGridState.findIndex(
          (node) => node.id === deletedNode.id
        );
        if (nodeIdx > -1) {
          newGridState.splice(nodeIdx, 1);
        }
      });
      setSelectedStateArray([]);
      setGridState({
        ...gridState,
        nodecollections: {
          ...gridState.nodecollections,
          data: newGridState,
          total: newGridState.length
        }
      });
      return;
    }
    // only handle single delete for now
    await store.dispatch(deleteNode(nodes.selectedData[0]));
    const newGridState = [...gridState.nodecollections.data];
    const nodeIdx = newGridState.findIndex(
      (node) => node.id === nodes.selectedData[0].id
    );
    if (nodeIdx > -1) {
      setSelectedStateArray([]);
      newGridState.splice(nodeIdx, 1);
    }
    setGridState({
      ...gridState,
      nodecollections: {
        ...gridState.nodecollections,
        data: newGridState,
        total: newGridState.length
      }
    });
  };

  // SETS ALL SELECTED ELEMENTS INTO STATE VAR //
  const onSelectAllChange = (e) => {
    if (!selectAll) {
      setSelectedElements({
        ...selectedElements,
        nodes: e.dataItems
      });
      setSelectedStateArray([...e.dataItems]);
      setSelectAll(!selectAll);
      store.dispatch(gridSelectedDataActions(e.dataItems));
    } else {
      setSelectedElements({
        ...selectedElements,
        nodes: []
      });
      setSelectedStateArray([]);
      store.dispatch(clearSelectedNodes());
      setSelectAll(!selectAll);
    }
  };

  useEffect(() => {
    updateGridState();
  }, [
    selectedProject,
    selectedBranch,
    canvasSelections.tableFormatted.nodes,
    canvasSelections.tableFormatted.neighborhoods
  ]);

  useEffect(() => {
    setLoading(true);
    setLoading(false);
    setSkipProcessing(page.skip);
  }, [filter, sort, page, selectedProject, selectedBranch]);

  useEffect(() => {
    if (headers) {
      const attrHeaders = headers.groupHeader
        .map((header) => {
          const key = Object.keys(header);
          return header[key].map((attribute) => {
            return {
              field: attribute.name,
              title: startCase(attribute.name),
              show: true,
              filterable: true,
              filter: 'text',
              width: '200px'
            };
          });
        })
        .flat();
      setGridState({
        ...gridState,
        gridDynamicColumns: [...defaultHeaders, ...attrHeaders]
      });
    }
  }, [headers, gridState.nodecollections]);

  return (
    <div className="container-fluid">
      <DeleteConfirmation
        showModal={showModal}
        setShowModal={setShowModal}
        deleteFn={deleteNodeHandler}
      />
      <div
        style={{
          height: '61vh',
          margin: '16px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <KendoGridBase
          data={gridState.nodecollections.data || []}
          gridColumns={gridState.gridDynamicColumns}
          setGridFilters={handleGridFilterChange}
          setGridSort={handleGridSortChange}
          updateGridData={gridSelectedDataActions}
          onSelectionChange={selectionChange}
          onRowSingleClick={selectionChange}
          onPageChange={handlePageChange}
          onSelectAllChange={onSelectAllChange}
          sorter={sort}
          rowHeight={40}
          skip={page.skip}
          take={page.take}
          total={
            gridState.nodecollections ? gridState.nodecollections.total : 0
          }
          pageSize={100}
          selectable="selected"
          pageable={{
            pageSizes: [10, 25, 50, 75, 100],
            messages: {
              empty: 'no data to display'
            }
          }}
          sortable
          filterable
          selectAll={selectAll}
        />
        <div className="container-fluid p-0 d-flex justify-content-between mt-2">
          <div className='row'>
            <div className='col-5'>
              <button
                className="btn btn-primary btn-sm me-2"
                type="button"
                onClick={() => {
                  actionHandler('edit', nodes.selectedData[0]);
                }}
                disabled={nodes.selectedData.length !== 1}
              >
                <i className="bi bi-pencil me-2" />
                Edit
              </button>
            </div>
            <div className='col-7'>
            <button
              className="btn btn-success btn-sm text-white me-2"
              type="button"
              onClick={() => {
                actionHandler('addNode', '(need function)');
              }}
              disabled={userObj.selectedBranch[0].branchStatus === 'published'}
            >
              <i className="bi bi-plus me-2" />
              Add Node
            </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-danger btn-sm text-white"
              type="button"
              onClick={() => {
                actionHandler('delete', selectedRowsStateArray);
              }}
              disabled={
                !selectedRowsStateArray.filter(
                  (node) => node.checkedOutBy === userObj.screenName
                ).length ||
                userObj.selectedBranch[0].branchStatus === 'published'
              }
            >
              <i className="bi bi-trash me-2" />
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
NodeCollectionGrid.propTypes = {
  setRefresh: PropTypes.func,
  getNodes: PropTypes.func,
  gridSelectedDataActions: PropTypes.func,
  setShow: PropTypes.func,
  refresh: PropTypes.bool,
  headers: PropTypes.object,
  nodes: PropTypes.object,
  selectedElements: PropTypes.object,
  setSelectedElements: PropTypes.func
};

export default NodeCollectionGrid;
