/**
 * @summary Node Actions
 * @file NodeActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  CREATE_NODE,
  GET_NODES,
  GRID_DATA_ACTIONS_SELECTIONS_NODES_FULLFILLED,
  CLEAR_NODES,
  EDIT_NODES,
  GET_NODE,
  REPLACE_TABLEVIEW_NODES,
  UPDATE_NODES_BY_NEIGHBORHOOD,
  SET_NEW_NODE,
  CLEAR_SELECTED_NODES,
  UPDATE_COMMENT,
  ADD_COMMENT,
  DELETE_COMMENT,
  DELETE_NODE,
  DELETE_MULTIPLE_NODES,
  GET_NODE_COPY
} from './NodeTypes';
import {
  REMOVE_NODES_FROM_NEIGHBORHOOD,
  REMOVE_NODES_FROM_NEIGHBORHOODS_MUTLIPLE
} from 'store/neighborhoods/NeighborhoodTypes';

export const createNode = (formValues) => async (dispatch) => {
  const resp = await axios.post(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/nodes`,
    { ...formValues }
  );
  dispatch({
    type: CREATE_NODE,
    node: formValues,
    meta: resp.data.node
  });
  return resp;
};

export const editNode = (formValues) => async (dispatch) => {
  const resp = await axios.put(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/nodes/${formValues.id}`,
    { ...formValues }
  );
  dispatch({
    type: EDIT_NODES,
    node: formValues,
    meta: resp.data
  });
  return resp;
};

export const clearNodes = () => ({ type: CLEAR_NODES });

export const getNodes = (projectId, branchId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/projects/${projectId}/branch/${branchId}/nodes`
  );
  if (data) {
    dispatch({
      type: GET_NODES,
      nodes: data
    });
  } else {
    dispatch(clearNodes());
  }
};

export const getNode = (projectId, branchId, nodeId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/projects/${projectId}/branch/${branchId}/node/${nodeId}`
  );
  if (data) {
    dispatch({
      type: GET_NODE,
      selectedData: [data]
    });
  }
};

export const getNodeCopy =
  (projectId, branchId, nodeId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/node/${nodeId}`
    );
    if (data) {
      dispatch({
        type: GET_NODE_COPY,
        selectedData: [data]
      });
    }
  };

export const replaceTableviewNodes = (nodes) => ({
  type: REPLACE_TABLEVIEW_NODES,
  nodes
});

export const getNodeSettings = (projectId, branchId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/projects/${projectId}/branch/${branchId}/nodeSettings`
  );
  return data;
};

const updateSelectedGrid = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_NODES_FULLFILLED,
  node: {
    data
  }
});

export const gridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateSelectedGrid(data));
};

export const deleteNode = (node) => async (dispatch) => {
  try {
    const resp = await axios.delete(
      `/api/projects/${node.projectId}/branch/${node.branchId}/nodes/${node.id}`
    );
    dispatch({
      type: DELETE_NODE,
      node
    });

    // also need to remove the node from any neighborhood
    dispatch({
      type: REMOVE_NODES_FROM_NEIGHBORHOOD,
      node
    });

    return resp;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMultipleNodes =
  (project, branch, nodeIds) => async (dispatch) => {
    try {
      const resp = await axios.post(
        `/api/projects/${project.id}/branch/${branch.id}/nodes/multiple`,
        { nodeIds }
      );
      dispatch({
        type: DELETE_MULTIPLE_NODES,
        nodeIds
      });

      // also need to remove the node from any neighborhood
      dispatch({
        type: REMOVE_NODES_FROM_NEIGHBORHOODS_MUTLIPLE,
        nodeIds
      });

      return resp;
    } catch (error) {
      console.log(error);
    }
  };

export const updateNodesByNeighborhood = (data) => {
  return {
    type: UPDATE_NODES_BY_NEIGHBORHOOD,
    nodeIds: data.nodeIds,
    selected: data.selected
  };
};

export const setNewNode = () => ({ type: SET_NEW_NODE });

export const clearSelectedNodes = () => ({ type: CLEAR_SELECTED_NODES });

export const getMemberships =
  (projectId, branchId, nodeId) => async (dispatch) => {
    const { data } = await axios.get(
      `/api/projects/${projectId}/branch/${branchId}/nodes/${nodeId}/memberships`
    );
    return data;
  };

export const updateComment = (formValues) => async (dispatch) => {
  const resp = await axios.put(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/nodes/${formValues.nodeId}/comments/${formValues.commentId}`,
    { ...formValues }
  );
  dispatch({
    type: UPDATE_COMMENT,
    commentId: resp.data.comment.id,
    comment: formValues
  });
  return resp;
};

export const addComment = (formValues) => async (dispatch) => {
  const resp = await axios.post(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/nodes/${formValues.nodeId}/comments`,
    {
      ...formValues
    }
  );
  dispatch({
    type: ADD_COMMENT,
    comment: resp.data.comment
  });
  return resp;
};

export const deleteNodeComment = (formValues) => async (dispatch) => {
  const resp = await axios.delete(
    `/api/projects/${formValues.projectId}/branch/${formValues.branchId}/nodes/${formValues.nodeId}/comments/${formValues.commentId}`,
    { ...formValues }
  );
  dispatch({
    type: DELETE_COMMENT,
    commentId: resp.data.id,
    comment: formValues
  });
  return resp;
};
