/**
 * @summary branchModal.js
 * @file Modal component that allows users to checkout nodes and create a new branch in table-view (ONLY IF the user is currently in 'published' branch)
 * @returns {JSX}
 * @usedBy tableviewLayout.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { getNodes, gridSelectedDataActions } from '../../../store/nodes/NodeActions';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import store from '../../../store/store';
import { createBranch } from '../../../store/branchesDetails/branchesDetailsActions';
import ValidationMsg from 'store/validation/ValidationMsg';
import { hydrateProject } from '../../../store/ProjectSetup/ProjectSetupActions';

const options = {
  messsageType: {
    success: 'Success'
  },
  messages: {
    successOnSave: 'Branch saved Successfully',
    errorOnSave: 'Unable to Save Branch'
  }
};

const BranchModal = ({ show, setShow }) => {
  // Grabs selected nodes in selectedData to show for checkout //
  const nodes = { ...store.getState().nodeReducer.selectedData };
  const { userObj, neighborhoods } = useSelector((store) => ({
    userObj: store.authReducer.userObj,
    neighborhoods: store.neighborhoodReducer.selectedData
  }));

  const loadProject = () => {
    store.dispatch(hydrateProject(userObj));
  };

  const nodeData = Object.values(nodes)
    .map((node) => {
      if (node && node?.id) {
        return node;
      }
      return null;
    })
    .filter(Boolean);

  let nodecollections = { data: nodeData, total: nodeData.length };

  if (!nodecollections.data.length) {
    nodecollections = { data: [], total: 0 };
  }

  const gridRef = useRef();

  const [nodeCols] = useState([
    { field: 'nodeKey', headerName: 'Node Key',
      headerCheckboxSelection: false,
      checkboxSelection: false,
    },
    { field: 'name', headerName: 'Node Name' },
    { field: 'description', headerName: 'Description' }
  ]);

  const [branchName, setBranchName] = useState('');

  useEffect(() => {
    loadProject();
    if (nodes?.id > 0) {
      store.dispatch(
        getNodes(
          userObj.selectedProject[0].id,
          userObj.selectedBranch[0].id
        )
      );
    }
  }, []);

  const saveBranch = () => {
    const json = {
      name: branchName,
      projectId: userObj.selectedProject[0].id,
      nodes: nodeData.map((node) => node.id),
      connections: [],
      neighborhoods: neighborhoods.map((neigh) => neigh.id),
      views: []
    };

    store
      .dispatch(createBranch(json))
      .then(() => {
        toast.success(options.messages.successOnSave);
      })
      .catch((errors) => {
        const errorMessages = [errors.response.data];
        toast.error(
          <ValidationMsg errorsArray={errorMessages} message={errors} />,
          { autoClose: 5000 }
        );
      });
    setBranchName('');
  };
  
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
    };
  }, []);
  
  const onFirstDataRendered = useCallback((params) => {
    // Might need the enterprise version for this fn //
    // gridRef.current.api.setNodesSelected({
    //   nodes: nodeData,
    //   newValue: true,
    // });
    gridRef.current.api.selectAll()
  }, []);

  return (
    <>
      <Modal show={show} backdrop="static" centered size="lg">
        <Modal.Header>
          <Modal.Title>Create a Branch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row  d-flex">
            <label className="col-sm-2 justify-center">Branch Name:</label>
            <div className="col">
              <input
                type="text"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                className="form-control form-control-sm modal-input"
                style={{ width: '100%' }}
                required
              />
            </div>
          </div>
          <div className="divider p-1"></div>
          <div
            className="ag-theme-alpine"
            style={{ height: 300, width: '100%', position: 'relative' }}
          >
            Nodes being checking out
            <AgGridReact
              ref={gridRef}
              columnDefs={nodeCols}
              rowData={nodeData}
              rowSelection="multiple"
              rowMultiSelectWithClick={true}
              defaultColDef={defaultColDef}
              onFirstDataRendered={onFirstDataRendered}
              // onSelectionChanged={onSelectionChanged}
            />
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger btn-sm text-white"
            onClick={() => setShow(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary btn-sm text-white"
            disabled={branchName.length < 1}
            onClick={() => {
              saveBranch();
              setShow(false);
            }}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BranchModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
};

export default BranchModal;
