/**
 * @summary NeighborhoodFilter.js
 * @file CanvasFilter.js Component: Controls All Neighborhood Filter Functionality - Selected in CanvasFilter.js
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import FilterAutoSuggest from './FilterAutoSuggest';

const NeighborhoodFilter = ({
    diagramData,
    renderMapData,
    unfilteredMapData,
    handleFilterData,
}) => {
    const [filterContext, setFilterContext] = useState('Neighborhood Name');
    
    // Node Key Selector States used in AutoSuggest
    const [neighborhoodSelection, setNeighborhoodSelection] = useState(null)
    const [neighborhoodInput, setNeighborhoodInput] = useState("")
    const [allAutoSuggestOptions, setAllAutoSuggestOptions] = useState([]);
    const [allSelectableSuggestions, setAllSelectableSuggestions] = useState([])
    const [autoSuggestPlaceholder, setAutoSuggestPlaceholder] = useState('Enter a Neighborhood Name');


    function handleFilterContext(e) {
      setNeighborhoodSelection(null);
      setFilterContext(e.target.value);
    }

    useEffect(() => {
      if(unfilteredMapData === null){
        setNeighborhoodSelection(null)
        setNeighborhoodInput("")
        setAutoSuggestPlaceholder("Start Typing a " + filterContext + "...")
        setFilterContext(filterContext)
      }
    },[unfilteredMapData])

    useEffect(() => {
      // If Project Data is Present and Filter Menu is Open...
      if(diagramData) {
        setAllAutoSuggestOptions([])
        if (filterContext === 'Neighborhood Name') {
          let newAutosuggestOptions = []
          diagramData.nodeDataArray.forEach(node => {
            if(node.category === "Super"){
              newAutosuggestOptions.push(node.title)
            }
          })
          setAllAutoSuggestOptions(newAutosuggestOptions)
          setAutoSuggestPlaceholder('Enter Neighborhood Name');
        } else if (filterContext == 'Neighborhood Description') {
          let newAutosuggestOptions = []
          diagramData.nodeDataArray.forEach(node => {
            if(node.category === "Super"){
              newAutosuggestOptions.push(node.description)
            }
          })
          setAllAutoSuggestOptions(newAutosuggestOptions)
          setAutoSuggestPlaceholder('Enter Neighborhood Description');
        }
      }
    }, [renderMapData]);

    useEffect(() => {
      // If Project Data is Present and Filter Menu is Open...
      if(diagramData) {
        setNeighborhoodSelection(null)
        setAllAutoSuggestOptions([])
        if (filterContext === 'Neighborhood Name') {
          let newAutosuggestOptions = []
          diagramData.nodeDataArray.forEach(node => {
            if(node.category === "Super"){
              newAutosuggestOptions.push(node.title)
            }
          })
          setAllAutoSuggestOptions(newAutosuggestOptions)
          setAutoSuggestPlaceholder('Enter Neighborhood Name');
        } else if (filterContext == 'Neighborhood Description') {
          let newAutosuggestOptions = []
          diagramData.nodeDataArray.forEach(node => {
            if(node.category === "Super"){
              newAutosuggestOptions.push(node.description)
            }
          })
          setAllAutoSuggestOptions(newAutosuggestOptions)
          setAutoSuggestPlaceholder('Enter Neighborhood Description');
        }
      }
    }, [filterContext]);

    // IF USER CHANGES MAP SELECTION, UPDATE THE FOCUS CHANGE
    useEffect(() => {
      if(neighborhoodSelection !== null){
        handleFilterData(neighborhoodSelection)
      } else {
    // ERROR MESSAGE NEEDED
      }
    },[neighborhoodSelection]) 

    function handleNeighborhoodSelection(selectedAutosuggestItem){
      if (filterContext === 'Neighborhood Name') {
        let neighborhoodSelected = diagramData.nodeDataArray.find(node => node.title === selectedAutosuggestItem)
        setNeighborhoodSelection(neighborhoodSelected)
      } else if (filterContext == 'Neighborhood Description') {
        let neighborhoodSelected = diagramData.nodeDataArray.find(node => node.description === selectedAutosuggestItem)
        setNeighborhoodSelection(neighborhoodSelected)
      }
    }
    return(
        <div className="row">
          {/* CHOOSE CONTEXT COLUMN */}
          {/* CHANGING THE TARGET CONTEXT CHANGES THE AUTOPOPULATE PRESENTATION OF OPTIONS */}
          <div className="col-3">
            <form>
              <div className="row">
                <label className="filter-field-titles" htmlFor="filterContext">
                  Filter Property
                </label>
              </div>
              <select
                id="filterContext"
                className="filter-dropdowns"
                onChange={handleFilterContext}
                value={filterContext}
              >
                <option value="Neighborhood Name">Name</option>
                <option value="Neighborhood Description">Description</option>
              </select>
            </form>
          </div>
          {/* ENTER CONTENT COLUMN */}
          <div className="col-9">
            <div className="filter-inputs">
              <div className="row">
                <label className="filter-field-titles" htmlFor="filterTarget">
                  Filter Target
                  <i className="requiredField"> *</i>
                </label>
              </div>
              {/* AUTOSUGGEST - THE MEAT OF THIS ENTIRE FUNCTION */}
              <FilterAutoSuggest
                autoSuggestInput={neighborhoodInput}
                setAutoSuggestInput={setNeighborhoodInput}
                handleSelection={handleNeighborhoodSelection}
                allAutoSuggestOptions={allAutoSuggestOptions}
                allSelectableSuggestions={allSelectableSuggestions}
                setAllSelectableSuggestions={setAllSelectableSuggestions}
                autoSuggestPlaceholder={autoSuggestPlaceholder}
              />
            </div>
          </div>
          {/* FILTER RADIUS COLUMN */}
          {/* When the filter element is "node," the user must enter a radius. This determines how many connections outward from the target node we should be collecting nodes for the filter */}
          {/* When the filter element is "neighborhood," this option is disabled */}
        </div>
    )
}

export default NeighborhoodFilter;

NeighborhoodFilter.propTypes = {
  diagramData: PropTypes.object,
  unfilteredMapData: PropTypes.any,
  handleFilterData: PropTypes.func,
  renderMapData: PropTypes.obj,
};