/**
 * @summary NodeRadiusFilter.js
 * @file CanvasFilter.js Component: Controls All Node Radius Filter Functionality - Selected in CanvasFilter.js
 * @returns {JSX}
 * @usedBy CanvasFilter.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import FilterAutoSuggest from './FilterAutoSuggest';

const NodeRadiusFilter = ({
    diagramData,
    renderMapData,
    unfilteredMapData,
    handleFilterData,
}) => {
  
  const [filterContext, setFilterContext] = useState('Node Key');
  // Node Radius Inputs
  const [radiusNumber, setRadiusNumber] = useState(1);

  // Node Key Selector States used in AutoSuggest
  const [nodeSelection, setNodeSelection] = useState(null)
  const [nodeInput, setNodeInput] = useState("")
  const [allAutoSuggestOptions, setAllAutoSuggestOptions] = useState([]);
  const [allSelectableSuggestions, setAllSelectableSuggestions] = useState([])
  
  const [autoSuggestPlaceholder, setAutoSuggestPlaceholder] = useState('');
  
  // WHEN USER CHANGES TARGET CONTEXT (NAME, KEY, DESCRIPTION), SET NEW CONTEXT
  // This state dictates how the AutoComplete will present the user's final filter target options
  function handleFilterContext(e) {
    setNodeSelection(null);
    setNodeInput("")
    setAutoSuggestPlaceholder("")
    setFilterContext(e.target.value);
  }
  useEffect(() => {
    if(unfilteredMapData === null){
      setNodeSelection(null)
      setNodeInput("")
      setAutoSuggestPlaceholder("Start Typing a " + filterContext + "...")
      setFilterContext(filterContext)
    }
  },[renderMapData, unfilteredMapData])
  
  // WHEN CONTEXT OPTION CHANGES
  useEffect(() => {
  // If Project Data is Present and Filter Menu is Open...
    if (renderMapData) {
      setNodeSelection(null)
      if (filterContext === 'Node Key') {
        let newAutosuggestOptions = renderMapData.nodeDataArray.map(node => {
          if(node.category !== "Super"){
            return node.nodeKey
          }
        })
        setAllAutoSuggestOptions(newAutosuggestOptions)
        setAutoSuggestPlaceholder('Start Typing a Node Key');
      } else if (filterContext === 'Node Name') {
        let newAutosuggestOptions = []
        renderMapData.nodeDataArray.forEach(node => {
          if(node.category !== "Super"){
            newAutosuggestOptions.push(node.title)
          }
        })
        setAllAutoSuggestOptions(newAutosuggestOptions)
        setAutoSuggestPlaceholder('Start Typing a Node Name');
      } else if (filterContext == 'Node Description') {
        let newAutosuggestOptions = []
        renderMapData.nodeDataArray.forEach(node => {
          if(node.category !== "Super"){
            newAutosuggestOptions.push(description)
          }
        })
        setAllAutoSuggestOptions(newAutosuggestOptions)
        setAutoSuggestPlaceholder('Start Typing a Node Description');
      }
    }
  }, [renderMapData, filterContext]);

  // WHEN USER CHANGES THE NODE RADIUS, SET THE NEW RADIUS NUMBER
  function handleRadiusChange(e) {
    setRadiusNumber(Number(e.target.value));
  }

  // IF USER CHANGES MAP SELECTION, UPDATE THE FOCUS CHANGE
  useEffect(() => {
    if(nodeSelection !== null){
      let nodeRadiusFilterData = {
        nodeRadiusFocus: nodeSelection,
        radiusNumber,
      }
      handleFilterData(nodeRadiusFilterData)
    } else {
  // ERROR MESSAGE NEEDED
    }
  },[nodeSelection, radiusNumber])  

  function handleNodeSelection(selectedAutosuggestItem){
    if (filterContext === 'Node Key') {
      let nodeSelected = diagramData.nodeDataArray.find(node => node.nodeKey === selectedAutosuggestItem)
      setNodeSelection(nodeSelected)
    } else if (filterContext === 'Node Name') {
      let nodeSelected = diagramData.nodeDataArray.find(node => node.title === selectedAutosuggestItem)
      setNodeSelection(nodeSelected)
    } else if (filterContext == 'Node Description') {
      let nodeSelected = diagramData.nodeDataArray.find(node => node.description === selectedAutosuggestItem)
      setNodeSelection(nodeSelected)
    }
  }

  return(
      <div className="row">
        {/* CHOOSE CONTEXT COLUMN */}
        {/* CHANGING THE TARGET CONTEXT CHANGES THE AUTOPOPULATE PRESENTATION OF OPTIONS */}
        <div className="col-3">
          <form>
            <div className="row">
              <label className="filter-field-titles" htmlFor="filterContext">
                Filter Property
              </label>
            </div>
            <select
              id="filterContext"
              className="filter-dropdowns"
              onChange={handleFilterContext}
              value={filterContext}
            >
              <option>Node Key</option>
              <option>Node Name</option>
              <option>Node Description</option>
            </select>
          </form>
        </div>
        {/* ENTER CONTENT COLUMN */}
        <div className="col-6">
          <form>
            <div className="row">
              <label className="filter-field-titles" htmlFor="filterTarget">
                Filter Target
                <i className="requiredField"> *</i>
              </label>
            </div>
            <FilterAutoSuggest
              autoSuggestInput={nodeInput}
              setAutoSuggestInput={setNodeInput}
              handleSelection={handleNodeSelection}
              allAutoSuggestOptions={allAutoSuggestOptions}
              allSelectableSuggestions={allSelectableSuggestions}
              setAllSelectableSuggestions={setAllSelectableSuggestions}
              autoSuggestPlaceholder={autoSuggestPlaceholder}
            />
          </form>
        </div>
        {/* FILTER RADIUS COLUMN */}
        {/* When the filter element is "node," the user must enter a radius. This determines how many connections outward from the target node we should be collecting nodes for the filter */}
        {/* When the filter element is "neighborhood," this option is disabled */}
        <div className="col-3">
          <form>
            <div className="row">
              <label className="filter-field-titles">Filter Radius</label>
            </div>
            <select
              className="filter-dropdowns"
              id="filterRadius"
              value={radiusNumber}
              onChange={handleRadiusChange}
            >
                <>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </>  
            </select>
          </form>
        </div>
      </div>
  )
}

export default NodeRadiusFilter;

NodeRadiusFilter.propTypes = {
  diagramData: PropTypes.object,
  renderMapData: PropTypes.object,
  unfilteredMapData: PropTypes.object,
  handleFilterData: PropTypes.func,
};