/**
 * @summary Views Actions
 * @file ViewActions.js
 * @returns {Redux State}
 * @usedBy Application
 * @author Dj Ritchey
 * @since 07/01/2021
 * @lastUpdated 05/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import axios from 'axios';
import {
  CREATE_VIEW,
  GET_VIEWS,
  GET_VIEW,
  GRID_DATA_ACTIONS_SELECTIONS_VIEWS_FULLFILLED,
  CLEAR_VIEWS,
  EDIT_VIEWS,
  RESET_SELECTED_VIEW,
  DELETE_VIEW,
  ACTIVATE_VIEW
} from './ViewTypes';

export const createView = (formValues) => async (dispatch) => {
  const resp = await axios.post(`/api/projects/${formValues.projectId}/views`, {
    ...formValues
  });
  dispatch({
    type: CREATE_VIEW,
    view: formValues,
    meta: resp.data.view
  });
  return resp;
};

export const editView = (formValues) => async (dispatch) => {
  const resp = await axios.put(
    `/api/projects/${formValues.projectId}/views/${formValues.id}`,
    { ...formValues }
  );
  dispatch({
    type: EDIT_VIEWS,
    view: formValues,
    meta: resp.data
  });
  return resp;
};

export const clearViews = () => ({ type: CLEAR_VIEWS });

export const getViews = (projectId, branchId) => async (dispatch) => {
  const { data } = await axios.get(`/api/projects/${projectId}/views`);
  if (data) {
    dispatch({
      type: GET_VIEWS,
      views: data
    });
  } else {
    dispatch(clearViews());
  }
};

/// NEW ///
export const getView = (projectId, branchId, viewId) => async (dispatch) => {
  const { data } = await axios.get(
    `/api/projects/${projectId}/views/${viewId}`
  );
  if (data) {
    dispatch({
      type: GET_VIEW,
      views: data
    });
  }
};
/// NEW ///

const updateSelectedGrid = (data) => ({
  type: GRID_DATA_ACTIONS_SELECTIONS_VIEWS_FULLFILLED,
  view: {
    data
  }
});

export const gridSelectedDataActions = (data) => (dispatch) => {
  dispatch(updateSelectedGrid(data));
};

const resetSelectedView = (data) => ({
  type: RESET_SELECTED_VIEW,
  view: undefined
});

export const resetSelectedViewDataAction = () => (dispatch) => {
  dispatch(resetSelectedView());
};

export const deleteView = (projectId, viewId) => async (dispatch) => {
  const { data } = await axios.delete(
    `/api/projects/${projectId}/views/${viewId}`
  );
  if (data) {
    dispatch({
      type: DELETE_VIEW,
      views: data
    });
  }
};

export const activateView = (projectId, viewId) => async (dispatch) => {
  const { data } = await axios.put(
    `/api/projects/${projectId}/views/${viewId}/activate`
  );
  if (data) {
    dispatch({
      type: ACTIVATE_VIEW,
      views: data
    });
  }
};
