/**
 * @summary findSnapshotDifference.js
 * @file Function looks for differences of element name, description, attribute, unique attributes, unique elements, and returns an object of all the number of differences
 * @returns {JSX}
 * @usedBy ReportPage.jsx
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

//////////////////////////////////////////////////
// FINDS FOR ANY DIFFERENRES BETWEEN SNAPSHOTS //
/////////////////////////////////////////////////
// DIFFERENCE LOGS CAN BE FOUND BELOW IN THIS ORDER //
// 1) Different Names for Elements A and B 
// 2) Different Descriptions for Elements A and B - Connections Excluded
// 3) Additional Attributes in Element B
// 4) Missing Attributes in Element B
// 5) Different Values for Attributes A and B
// 6) Unique Elements in A or B
// NEIGHBORHOODS ONLY
// 8) Different Node Members for Neighborhoods A and B
// CONNECTIONS ONLY
// 9) Different Destination Node
// 10) Different Destination Node
export const findSnapshotDifferences = (formattedSnapshotsAandB, elementType) => {
    //////////////////////////
    // ELEMENTS DIFFERENCES //
    //////////////////////////
    let elementsA = formattedSnapshotsAandB[0];
    let elementsB = formattedSnapshotsAandB[1];
    // let elementType = elementsA[0] ? elementsA[0].type.toString().replace(/\b\w/g, l => l.toUpperCase()) : elementsB[0].type.toString().replace(/\b\w/g, l => l.toUpperCase());

    let elementIncongruencies = []
    let attributeIncongruencies = []
    let uniqueElements = []
    let uniqueAttributes = []
    // Array of Elements in SnapshotA that are Missing from SnaptshotB
    let uniqueElementsInA = []
    // LOOPING SNAPSHOT A TO FIND MATCHES IN SNAPSHOT B
    for(let i=0; i < elementsA.length; i++){
        let loopedElementA = elementsA[i]
        let matchedElementB = elementsB.find(elementB => elementB.key === elementsA[i].key)
        // IF MATCH FOUND, CONTINUE COMPARING - ELSE PUSH THIS ELEMENT TO MISSING ARRAY //
        if(matchedElementB !== undefined){
            // FINDING ELEMENT NAME OR DESCRIPTION DIFFERENCES //
            if(loopedElementA.key === matchedElementB.key){
                ///////////////////////////////////////
                // DIFFERENCE 1) DIFFERENT ELEMENT NAME //
                ///////////////////////////////////////
                if(loopedElementA.name !== matchedElementB.name){
                    let change = {
                        property: elementType,
                        key: loopedElementA.key,
                        change: "Different " + elementType + " Names",
                        snapshotA: loopedElementA.name,
                        snapshotB: matchedElementB.name,
                    }
                    elementIncongruencies.push(change)
                } 
                //////////////////////////////////////////////
                // DIFFERENCE 2) DIFFERENT ELEMENT DESCRIPTION //
                //////////////////////////////////////////////
                if(loopedElementA.type !== "connection"){
                    if (loopedElementA.description !== matchedElementB.description){
                        function stripHtml(html) {
                            let doc = new DOMParser().parseFromString(html, 'text/html');
                            return doc.body.textContent || "";
                        }
                        let change = {
                            property: elementType,
                            key: loopedElementA.key,
                            change: "Different " + elementType + " Descriptions",
                            snapshotA: stripHtml(loopedElementA.description),
                            snapshotB: stripHtml(matchedElementB.description),
                        }
                        elementIncongruencies.push(change)
                    }
                }

                // FINDING ATTRIBUTE DIFFERENCES //
                if(loopedElementA.attributes.length > 0 || matchedElementB.attributes.length > 0){
                    let attributesA = loopedElementA.attributes.filter(attribute => attribute );
                    let attributesB = matchedElementB.attributes.filter(attribute => attribute);
                    let uniqueAttributesInA = []
                    for(let i=0; i < attributesA.length; i++){
                        let loopedAttributeA = attributesA[i]
                        let matchedAttributeB = attributesB.find(attB => attB.attributeName === loopedAttributeA.attributeName)
                        if(matchedAttributeB !== undefined){
                            ///////////////////////////////////////////
                            // DIFFERENCE 3) DIFFERENT ATTRIBUTE VALUES //
                            ///////////////////////////////////////////
                            if (JSON.stringify(loopedAttributeA.attributeValues) !== JSON.stringify(matchedAttributeB.attributeValues)) {
                                let change = {
                                    property: elementType + " Attribute",
                                    key: loopedElementA.key,
                                    attributeKey: loopedAttributeA.attributeName,
                                    change: "Different Values for " + loopedAttributeA.attributeName.replace(/\b\w/g, l => l.toUpperCase()) + " Attribute",
                                    snapshotA: JSON.stringify(loopedAttributeA.attributeValues),
                                    snapshotB: JSON.stringify(matchedAttributeB.attributeValues),
                                }
                                attributeIncongruencies.push(change);
                            }
                        } else {
                            uniqueAttributesInA.push(loopedAttributeA)
                        }      
                    }  
 
                    ///////////////////////////////////////////////
                    // DIFFERENCE 4) UNIQUE ATTRIBUTES IN A OR B //
                    ///////////////////////////////////////////////
                    // IF MAP A HAS UNIQUE ATTRIBUTES || OR IF MAP B HAS ATTRIBUTES NOT FOUND IN MAP A... continue
                    if(uniqueAttributesInA.length > 0 || attributesA.length - uniqueAttributesInA.length !== attributesB.length) {
                        // IF MAP A'S UNIQUE ATTRIBUTES ACCOUNT FOR EVERYTHING IN MAP B... continue
                        if(attributesA.length - uniqueAttributesInA.length === attributesB.length){
                            let renderChangeMessage = () => {
                                // SINGLE UNIQUE ATTRIBUTE IN MAP A
                                if(uniqueAttributesInA.length === 1){
                                    return "One Unique Attribute"
                                // PLURAL UNIQUE ATTRIBUTES IN MAP A
                                } else {
                                    return uniqueAttributesInA.length.toString() + " Unique Attributes"
                                }
                            }
                            let changeMessage = renderChangeMessage()
                            let change = {
                                property: uniqueAttributesInA.length === 1 ? elementType + " Attribute" : elementType + " Attributes",
                                key: loopedElementA.key,
                                change: changeMessage,
                                snapshotA: uniqueAttributesInA,
                                snapshotB: [],
                                amountSnapshotA: uniqueAttributesInA.length,
                                amountSnapShotB: 0,
                            }
                            uniqueAttributes.push(change)
                        } else {
                            let uniqueAttributesInB = attributesB.filter(attB => {
                                if(!attributesA.some(attA => attA.attributeName === attB.attributeName)) {
                                    return attB.attributeName
                                }
                            })
                            let renderChangeMessage = () => {
                                if(uniqueAttributesInA.length > 0 && uniqueAttributesInB.length > 0){
                                    return (uniqueAttributesInA.length + uniqueAttributesInB.length).toString() + " Unique Attributes"
                                } else if(uniqueAttributesInA.length === 0 && uniqueAttributesInB.length > 1){
                                    return uniqueAttributesInB.length.toString() + " Unique Attributes"
                                } else {
                                    return "One Unique Attribute"
                                }
                            }
                            let changeMessage = renderChangeMessage()
                            let change = {
                                property: uniqueAttributesInB.length === 1 ? elementType + " Attribute" : elementType + " Attributes",
                                key: matchedElementB.key,
                                change: changeMessage,
                                snapshotA: uniqueAttributesInA,
                                snapshotB: uniqueAttributesInB,
                                amountSnapshotA: uniqueAttributesInA.length,
                                amountSnapShotB: uniqueAttributesInB.length,
                            }
                            uniqueAttributes.push(change)
                        }
                    }
                }
            
            }
            
        // PUSH ANY ELEMENTS MISSINGS FROM SNAPSHOT B //
        } else {
            uniqueElementsInA.push(loopedElementA)
        }        
    }
    
    /////////////////////////////////////////////
    // DIFFERENCE 5) UNIQUE ELEMENTS IN A OR B //
    /////////////////////////////////////////////
    // IF MAP A HAS UNIQUE ELEMENTS || OR IF MAP B HAS ELEMENTS NOT FOUND IN MAP A... continue
    if(uniqueElementsInA.length > 0 || elementsA.length - uniqueElementsInA.length !== elementsB.length) {
        // IF MAP A'S UNIQUE ATTRIBUTES ACCOUNT FOR EVERYTHING IN MAP B... continue
        if(elementsA.length - uniqueElementsInA.length === elementsB.length){
            let renderChangeMessage = () => {
                // SINGLE UNIQUE ATTRIBUTE IN MAP A
                if(uniqueElementsInA.length === 1){            
                    return "One Unique " + elementType + " in Map A"
                // PLURAL UNIQUE ATTRIBUTES IN MAP A
                } else {
                    return uniqueElementsInA.length + " Unique " + elementType + "s in Map A"
                }
            }
            let changeMessage = renderChangeMessage()
            let change = {
                    property: elementType,
                    key: uniqueElementsInA.length === 1 ? uniqueElementsInA[0].nodeKey : "Multiple",
                    change: changeMessage,
                    snapshotA: uniqueElementsInA,
                    snapshotB: [],
                    amountSnapshotA: uniqueElementsInA.length,
                    amountSnapShotB: 0,
                }
            uniqueElements.push(change)
        } else {
            let uniqueElementsInB = elementsB.filter(elementB => !elementsA.some(elementA => elementA.key === elementB.key))
            
            let renderChangeMessage = () => {
                // UNIQUE ELEMENTS THROUGHOUT BOTH MAPS
                if(uniqueElementsInA.length > 0 && uniqueElementsInB.length > 0){
                    return (uniqueElementsInA.length + uniqueElementsInB.length).toString() + " Unique " + elementType + "s"
                // PLURAL UNIQUE ELEMENTS IN MAP B
                } else if(uniqueElementsInA.length === 0 && uniqueElementsInB.length > 1){
                    return uniqueElementsInB.length + " Unique " + elementType + "s in Map B"
                // SINGLE UNIQUE ELEMENT IN MAP B
                } else if(uniqueElementsInA.length === 0 && uniqueElementsInB.length === 1){
                    return " One Unique " + elementType + " in Map B"
                } else {
                    return "Unique " + elementType 
                }
            }
            let renderElementKey = () => {
                if(uniqueElementsInA.length > 1 || uniqueElementsInB.length > 1){
                    return "Multiple"
                } else if (uniqueElementsInA.length === 1 && uniqueElementsInB.length === 0) {
                    return uniqueElementsInA[0].nodeKey
                } else if (uniqueElementsInA.length === 0 && uniqueElementsInB.length === 1){
                    return uniqueElementsInA[1].nodeKey
                } else {
                    return "Multiple"
                }
            }
            let changeMessage = renderChangeMessage()
            let elementKey = renderElementKey()
            let change = {
                    property: elementType,
                    key: elementKey,
                    change: changeMessage,
                    snapshotA: uniqueElementsInA,
                    snapshotB: uniqueElementsInB,
                    amountSnapshotA: uniqueElementsInA.length,
                    amountSnapshotB: uniqueElementsInB.length,
                }
            uniqueElements.push(change)
        }
    }
    const sumOfAllDifferences = uniqueElements.length + elementIncongruencies.length + uniqueAttributes.length + attributeIncongruencies.length
    const snapshotComparisonLog = {
        count: sumOfAllDifferences,
        uniqueElements,
        countUniqueElements: uniqueElements.length,
        elementIncongruencies,
        countUniqueElements: elementIncongruencies.length,
        uniqueAttributes,
        countUniqueElements: uniqueAttributes.length,
        attributeIncongruencies,
        countAttributeIncongruencies: attributeIncongruencies.length
    }

    // RETURNS THE TOTAL LIST OF DIFFERENCES BETWEEN THESE TWO ELEMENTS
    return snapshotComparisonLog
}