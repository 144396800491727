/**
 * @summary tableviewLayout.js
 * @file Provides a table layout for the node, connection, and neighborhood collection pages
 * @returns {JSX}
 * @usedBy routes.js
 * @author Sam Lee
 * @since 2/17/2022
 * @lastUpdated 04/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import NodeCollectionPage from 'pages/project/nodes/NodeCollectionPage';
import ConnectionCollectionPage from 'pages/project/connections/ConnectionCollectionPage';
import NeighborhoodCollectionPage from 'pages/project/neighborhoods/NeighborhoodCollectionPage';
import BranchModal from 'pages/project/modal/branchModal';
import { updateUserTab } from 'store/auth/AuthActions';
import store from 'store/store';
import axios from 'axios';
import { toggleFilterTableView } from 'store/auth/AuthActions';
import {
  resetCanvasSelection,
  addDataToCanvasSelection,
  setCanvasSelections
} from 'store/canvas/CanvasActions';
import { useLocation, useNavigate } from 'react-router-dom';
import formatCanvasMapData from 'pages/canvas/utils/formatCanvasMapData';
import Button from 'react-bootstrap/Button';

const TableViewLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [headers, setHeaders] = useState({});
  const [selectedElements, setSelectedElements] = useState({
    nodes: [],
    connections: [],
    neighborhoods: []
  });

  const selectProject = state => state.authReducer.userObj.selectedBranch[0];
  const selectBranch = state => state.authReducer.userObj.selectedProject[0];
  const selectUserTab = state => state.authReducer.userTab;
  const selectConnections = state => state.connectionReducer;
  const selectedBranch = useSelector(selectBranch);
  const selectedProject = useSelector(selectProject);
  const userTab = useSelector(selectUserTab);
  const connections = useSelector(selectConnections);

  const getHeaders = async () => {
    const resp = await axios.get(
      `/api/${selectedProject.id}/branch/${selectedBranch.id}/getTableHeaders`
    );
    setHeaders(resp.data);
  };

  useEffect(() => {
    getHeaders();
  }, [selectedProject, selectedBranch]);

  // TABLEVIEW NAVIGATION HANDLER
  async function handleCanvasViewNavigation() {
    const selectedNodes =
      (await selectedElements?.nodes.length) > 0 ? selectedElements?.nodes : [];
    const selectedNeighborhoods =
      (await selectedElements?.neighborhoods.length) > 0
        ? selectedElements?.neighborhoods
        : [];
    store.dispatch(resetCanvasSelection());

    selectedNeighborhoods.forEach((neighborhood) => {
      const validatedIds = neighborhood.nodeIds.filter((id) => {
        return selectedNodes.findIndex((node) => node.id === id) !== -1;
      });

      const validatedKeys = neighborhood.nodeKeys.filter((nodeKeyObj) => {
        return (
          selectedNodes.findIndex(
            (node) => node.nodeKey === nodeKeyObj.nodeKey
          ) !== -1
        );
      });

      neighborhood.nodeIds = validatedIds;
      neighborhood.nodeKeys = validatedKeys;
    });

    delete connections.selectedData;
    const connArr = Object.values(connections);
    const selectedConns = [];
    connArr.forEach((conn) => {
      const destination = selectedNodes.findIndex(
        (n) => n.id === conn.destination_node_id
      );
      const origin = selectedNodes.findIndex(
        (n) => n.id === conn.origin_node_id
      );
      if (destination >= 0 && origin >= 0) {
        selectedConns.push(conn);
      }
    });

    const mapData = formatCanvasMapData(
      selectedProject,
      selectedBranch,
      selectedNodes,
      selectedConns,
      selectedNeighborhoods
    );

    store.dispatch(setCanvasSelections('canvasFormatted', mapData));

    store.dispatch(toggleFilterTableView(true));
    navigate('/canvas', { state: { from: 'tableViewPage' } });
  }

  const handleResetButton = () => {
    //reset all selected nodes, connections and neighborhoods
    store.dispatch(resetCanvasSelection());
    location.state = null;

  };
  return (
    <div className="tableview-tabs">
      <BranchModal show={show} setShow={setShow} />
      <div className="d-flex justify-content-between">
        <div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => handleResetButton()}
          >
            Reset Table Data
          </button>
        </div>

        <div  className="d-flex">
          <div className=" me-2">
            <button
              className="btn btn-dark"
              type="button"
              onClick={() => {
                setShow(true);
              }}
              disabled={selectedProject.branchStatus !== 'published'}
            >
              <i className="bi bi-plus p-2" />
              Create Branch
            </button>
            </div>
            <div>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleCanvasViewNavigation()}
              disabled={selectedElements.nodes.length === 0 && selectedElements.neighborhoods.length === 0}
            >
              Redirect To Canvas
            </button>
          </div>
        </div>

      </div>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${userTab === 'nodes' ? 'active' : null}`}
            id="node-tab"
            data-bs-toggle="tab"
            data-bs-target="#nodes"
            type="button"
            role="tab"
            onClick={() => {
              store.dispatch(updateUserTab('nodes'));
            }}
            aria-controls="nodes"
            aria-selected="true"
          >
            Nodes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${
              userTab === 'connections' ? 'active' : null
            }`}
            id="connections-tab"
            data-bs-toggle="tab"
            data-bs-target="#connections"
            type="button"
            role="tab"
            onClick={() => {
              store.dispatch(updateUserTab('connections'));
            }}
            aria-controls="profile"
            aria-selected="false"
          >
            Connections
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${
              userTab === 'neighborhoods' ? 'active' : null
            }`}
            id="neighborhoods-tab"
            data-bs-toggle="tab"
            data-bs-target="#neighborhoods"
            type="button"
            role="tab"
            onClick={() => {
              store.dispatch(updateUserTab('neighborhoods'));
            }}
            aria-controls="neighborhoods"
            aria-selected="false"
          >
            Neighborhoods
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade show ${
            userTab === 'nodes' ? 'active' : null
          }`}
          id="nodes"
          role="tabpanel"
          aria-labelledby="node-tab"
        >
          <NodeCollectionPage
            setShow={setShow}
            headers={headers.nodes}
            selectedElements={selectedElements}
            setSelectedElements={setSelectedElements}
          />
        </div>
        <div
          className={`tab-pane fade show ${
            userTab === 'connections' ? 'active' : null
          }`}
          id="connections"
          role="tabpanel"
          aria-labelledby="connections-tab"
        >
          <ConnectionCollectionPage
            headers={headers.connections}
            selectedElements={selectedElements}
            setSelectedElements={setSelectedElements}
          />
        </div>
        <div
          className={`tab-pane fade show ${
            userTab === 'neighborhoods' ? 'active' : null
          }`}
          id="neighborhoods"
          role="tabpanel"
          aria-labelledby="neighborhoods-tab"
        >
          <NeighborhoodCollectionPage
            setSelectedElements={setSelectedElements}
            selectedElements={selectedElements}
            headers={headers.neighborhoods}
          />
        </div>
      </div>
    </div>
  );
};

export default TableViewLayout;
