/**
 * @summary formatCanvasMapData.js
 * @file Extremely Important Util: Takes Our Data and Reformats it into a Data Obj that Can Be Used by GoJS
 * @returns {JSX}
 * @usedBy CanvasWrapper.js
 * @author Andy Greenhaw
 * @since 07/01/2021
 * @lastUpdated 12/19/2023
 * @PR - N/A
 * @copyright 2021 - 2024 University of Kansas
 */

const formatCanvasMapData = (
  project,
  branch,
  nodes,
  connections,
  neighborhoods
) => {
  ///////////////////
  // DEFAULT THEME //
  ///////////////////
  const defaultTheme = {
    nodeShape: 'RoundedRectangle',
    nodeFillColor: '#FFFFFF',
    nodeBorderColor: '#1560b7',
    nodeBorderStyle: ['stroke', 'color'],
    nodeBorderThickness: 2,
    nodeHighlightColor: 'red',
    connectionLineStyle: ['stroke', 'color'],
    connectionLineThickness: 2,
    connectionColor: '#000000',
    connectionArrowHeadStyle: 'Standard',
    neighborhoodShape: 'RoundedRectangle',
    neighborhoodBackgroundColor: '#FAF03B',
    neighborhoodBorderColor: '#1560b7',
    neighborhoodBorderThickness: 1,
    neighborhoodFontColor: '#000000',
    curve: null,
    nodePrimaryFont: 'Bolder 16px Arial',
    nodeSecondaryFont: '100 14px Arial',
    neighborhoodFont: 'Bolder 24px Arial',
    fontColor: '#000000',
    shape: 'RoundedRectangle',
    corners: 'Rounded',
    layoutDirection: 90,
    layeringOption: 'LayerLongestPathSource',
    arrowHeadStyle: 'Standard',
    maxLayer: 12
  };
  if (!project) {
    const mapDataObj = {
      projectHeadline: 'No Project Selected',
      nodeDataArray: [],
      linkDataArray: [],
      themeDataObj: defaultTheme,
      skipsDiagramUpdate: false
    };
    return mapDataObj;
  } else {
    /////////////////////////////////////////////
    // NEIGHBORHOOD FORMATTING INTO NODE ARRAY //
    /////////////////////////////////////////////
    const formatNeighborhoods = (neighborhoodItem) => {
      if (neighborhoodItem != undefined) {
        let newNeighborhoodFormat = {};
        newNeighborhoodFormat.id = neighborhoodItem.id;
        newNeighborhoodFormat.name = neighborhoodItem.name;
        newNeighborhoodFormat.version = neighborhoodItem.version;
        newNeighborhoodFormat.settings = neighborhoodItem.settings;
        if (neighborhoodItem.nodeIds) {
          newNeighborhoodFormat.nodes = neighborhoodItem.nodeIds.map((id) => {
            return nodes.find((node) => node.id === id);
          });
        }
        newNeighborhoodFormat.nodeKey = neighborhoodItem.id;
        newNeighborhoodFormat.description = neighborhoodItem.description
          ? neighborhoodItem.description
          : 'No Description';
        newNeighborhoodFormat.projectId = neighborhoodItem.projectId;
        newNeighborhoodFormat.linkageLevels = neighborhoodItem.linkageLevels;
        newNeighborhoodFormat.dash = neighborhoodItem.dash;
        newNeighborhoodFormat.supers = neighborhoodItem.supers;
        newNeighborhoodFormat.category = 'Super';
        newNeighborhoodFormat.visible = neighborhoodItem.visible || false;
        return newNeighborhoodFormat;
      }
    };
    const gojsNeighborhoods = neighborhoods?.map(formatNeighborhoods);
    nodes.push(...gojsNeighborhoods);
    //////////////////////
    // NODE FORMATTING //
    //////////////////////
    const formatNodes = (nodeItem) => {
      if (nodeItem !== undefined) {
        let formattedNodes = {};
        // ADDING KEY DATA PROPERTIES
        formattedNodes.visible = nodeItem.visible || true;
        formattedNodes.key = nodeItem.id;
        formattedNodes.nodeKey = nodeItem.nodeKey;
        formattedNodes.version = nodeItem.version;
        formattedNodes.title = nodeItem.name;
        formattedNodes.description = nodeItem.description
          ? new DOMParser().parseFromString(nodeItem.description, 'text/html')
              .body.textContent
          : 'No Description';
        formattedNodes.markdownDescription = nodeItem.description;
        formattedNodes.text = nodeItem.title;
        formattedNodes.connectedNodes = [];
        formattedNodes.checkedOutBy = nodeItem.checkedOutBy
          ? nodeItem.checkedOutBy
          : 'Open';
        formattedNodes.status = nodeItem.status;
        formattedNodes.comments = nodeItem.comments;
        formattedNodes.settings = nodeItem.settings;
        ///////////////////
        // ADDING THEMES //
        ///////////////////
        if (nodeItem.category !== 'Super') {
          formattedNodes.type = 'Node';

          if (nodeItem.theme) {
            formattedNodes.shape = nodeItem.theme.shape;
            formattedNodes.color = nodeItem.theme.color;
            formattedNodes.border = nodeItem.theme.border;
            formattedNodes.strokeWidth = nodeItem.theme.strokeWidth;

            formattedNodes.fontPrimary = nodeItem.theme.fontPrimary;
            formattedNodes.fontSecondary = nodeItem.theme.fontSecondary;
            formattedNodes.fontColor = nodeItem.theme.fontColor;

            formattedNodes.theme = nodeItem.theme;
          } else {
            formattedNodes.shape = defaultTheme.nodeShape;
            formattedNodes.color = defaultTheme.nodeFillColor;
            formattedNodes.border = defaultTheme.nodeBorderColor;
            formattedNodes.strokeWidth = defaultTheme.nodeBorderThickness;

            formattedNodes.fontPrimary = defaultTheme.nodePrimaryFont;
            formattedNodes.fontSecondary = defaultTheme.nodeSecondaryFont;
            formattedNodes.fontColor = defaultTheme.fontColor;
          }
        } else {
          formattedNodes.type = 'Super';
          if (nodeItem.theme) {
            formattedNodes.shape = nodeItem.theme.shape;
            formattedNodes.backgroundColor = nodeItem.theme.backgroundColor;
            formattedNodes.border = nodeItem.theme.border;
            formattedNodes.borderWidth = nodeItem.theme.borderWidth;

            formattedNodes.fontPrimary = nodeItem.theme.neighborhoodFont;
            formattedNodes.fontColor = nodeItem.theme.fontColor;

            formattedNodes.theme = nodeItem.theme;
          } else {
            formattedNodes.shape = defaultTheme.neighborhoodShape;
            formattedNodes.backgroundColor =
              defaultTheme.neighborhoodBackgroundColor;
            formattedNodes.border = defaultTheme.neighborhoodBorderColor;
            formattedNodes.borderWidth =
              defaultTheme.neighborhoodBorderThickness;

            formattedNodes.fontPrimary = defaultTheme.neighborhoodFont;
            formattedNodes.fontColor = defaultTheme.neighborhoodFontColor;
          }
        }

        /////////////////////////////
        // ASSIGNING NEIGHBORHOODS //
        /////////////////////////////
        if (nodeItem.category === 'Super') {
          formattedNodes.category = nodeItem.category;
          formattedNodes.color = nodeItem.color;
          formattedNodes.dash = nodeItem.dash;
          formattedNodes.visible = nodeItem.visible || false;
          formattedNodes.isLinkageLevel = nodeItem.isLinkageLevel;
          formattedNodes._members = [];
          if (nodeItem.linkageLevels?.length > 0) {
            for (let i = 0; i < nodeItem.linkageLevels.length; i++) {
              formattedNodes._members.push(nodeItem.linkageLevels[i].id);
            }
          }
          if (nodeItem.nodes) {
            nodeItem.nodes.forEach(function(node) {
              if(node.nodeKey) {
                formattedNodes._members.push(node.nodeKey);
              }
            });
          }
        }
        formattedNodes.supers = nodeItem.supers || [];
        gojsNeighborhoods.forEach(function (neighborhood) {
          if (neighborhood.nodes) {
            // Map Subordinate Nodes of Each Neighborhood
            neighborhood.nodes.forEach(function (neighborhoodNode) {
              // If a Node's Nodekey Matches a Neighborhood's SubNode's Nodekey...
              if (neighborhoodNode.nodeKey === nodeItem.nodeKey) {
                // This will enable GoJS to see what nodes each neighborhood needs to wrap around.
                formattedNodes.supers.push(neighborhood.id);
              }
            });
          }
        });
        return formattedNodes;
      } // close top if
    }; //close fn

    const gojsNodes = nodes.map(formatNodes);
    // const neighborhoodLegend = gojsNodes.filter(
    //   (node) => node.category === 'Super' && !node.isLinkageLevel
    // );
    ////////////////////////////
    // CONNECTIONS FORMATTING //
    ////////////////////////////
    const formatConnections = (connectionItem) => {
      if (connectionItem !== undefined) {
        const formatConnections = {};
        formatConnections.type = 'Connection';
        formatConnections.id = connectionItem.id;
        formatConnections.version = connectionItem.version;
        formatConnections.key = connections.indexOf(connectionItem) + 1;
        formatConnections.from = connectionItem.sourceNodeKey;
        formatConnections.to = connectionItem.destinationNodeKey;
        formatConnections.name = connectionItem.name;
        formatConnections.checkedOutBy = connectionItem.checkedOutBy
          ? connectionItem.checkedOutBy
          : 'Open';
        //////////////////////////////
        // ADDING CONNECTION THEMES //
        /////////////////////////////
        if (connectionItem.theme) {
          formatConnections.color = connectionItem.theme.color;
          formatConnections.strokeWidth = connectionItem.theme.strokeWidth;
          formatConnections.arrowHeadStyle =
            connectionItem.theme.arrowHeadStyle;
        } else {
          formatConnections.color = defaultTheme.connectionColor;
          formatConnections.strokeWidth = defaultTheme.connectionLineThickness;
          formatConnections.arrowHeadStyle =
            defaultTheme.connectionArrowHeadStyle;
        }
        return formatConnections;
      }
    };

    const gojsConnections = connections.map(formatConnections);

    nodes.push(...gojsNeighborhoods);

    ////////////////////////////////
    // FINAL FORMATTED MAP OBJECT //
    ////////////////////////////////

    const mapDataObj = {
      projectHeadline: project.displayTitle,
      selectedBranch: branch ? branch.name : userObj.selectedBranch[0].name,
      nodeDataArray: gojsNodes,
      linkDataArray: gojsConnections,
      modelData: defaultTheme,
      skipsDiagramUpdate: false
    };
    return mapDataObj;
  }
};

export default formatCanvasMapData;
